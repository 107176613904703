import type { SubmissionResult } from "@conform-to/react";
import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { useTranslation } from "react-i18next";
import {
  Form,
  Link,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
} from "react-router";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { z } from "zod";
import { getVerificationByTypeAndTarget } from "~/DatabaseInteractionClasses/VerificationDIC.server";
import { Field } from "~/components/Inputgroups/Form";
import { Logo } from "~/components/Logo";
import Button from "~/components/buttons/Button";
import ErrorBox from "~/components/error/ErrorBox";
import { COOKIE } from "~/constants/registrationconstants";
import {
  ROUTE_REGISTERCOMPANY,
  SUBMIT_INTENT,
} from "~/constants/routeConstants";
import { Login2FAFormSchema, LoginFormSchema } from "~/constants/zod.schema";
import {
  AUTH_COOKIEKEY,
  AUTH_STRATEGY_LOGIN,
  checkAuthenticationStatus,
} from "~/services/auth.server";
import { checkHoneypot } from "~/services/honeypot.server";
import { commitSession, getSession } from "~/services/session.server";
import { isCodeValid } from "~/services/verify.server";
import type { AuthenticatedIDs } from "~/types/AuthenticatedIDs";
import { getTheme } from "~/utils/theme.server";
import type { Route } from "./+types/login";
import { ThemeSwitch } from "./resources.theme-switch/route";
import { VerifySchema } from "./usersettings.2fa._index/route";

export const loader = async ({ request }: Route.LoaderArgs) => {
  let session = await getSession(request.headers.get(COOKIE));
  let login: AuthenticatedIDs = session.get(AUTH_COOKIEKEY);
  if (login) {
    throw redirect("/");
  }
  let theme = getTheme(request);
  return { theme: theme };
};

const INTENT_2FA = "login2fa";
const INTENT_LOGIN = "login";
const INTENT_FORGOTPASSWORD = "forgotpassword";

enum responseType {
  TwoFA,
  Error,
  CodeError,
}

interface SuccessResponse {
  type: responseType.TwoFA;
}

interface ErrorResponse {
  type: responseType.Error;
  error: string | null;
  response?: Response;
  submissionResult?: SubmissionResult<string[]>;
}

interface CodeErrorResponse {
  type: responseType.CodeError;
  error: string;
}

export const action: ({
  request,
}: Route.ActionArgs) => Promise<
  SuccessResponse | ErrorResponse | CodeErrorResponse
> = async ({ request }: Route.ActionArgs) => {
  let clonedRequest = request.clone(); // cloned, weil sonst das mitgeben der FormData nicht funktioniert "Response body object should not be disturbed or locked"
  let formData = await clonedRequest.formData();
  checkHoneypot(formData);
  let intent = formData.get(SUBMIT_INTENT);
  let authError: string;
  let verificationTypeAlready = "2fa";

  switch (intent) {
    case INTENT_FORGOTPASSWORD: {
      throw redirect("/forgotpassword");
    }
    case INTENT_LOGIN: {
      let auth: AuthenticatedIDs | null = null;
      try {
        auth = await checkAuthenticationStatus(request, AUTH_STRATEGY_LOGIN);
      } catch (e) {
        if (e instanceof Error) {
          authError = e.message;
        } else {
          throw e;
        }
      }

      if (auth) {
        // manually get the session
        let session = await getSession(request.headers.get(COOKIE));
        // and store the user data
        session.set(AUTH_COOKIEKEY, auth);

        // commit the session
        let headers = new Headers({
          "Set-Cookie": await commitSession(session),
        });

        let twoFactorEnabledAlready = await getVerificationByTypeAndTarget(
          verificationTypeAlready,
          auth?.loginID
        );

        if (twoFactorEnabledAlready) {
          return {
            type: responseType.TwoFA,
          };
        } else {
          throw redirect("/", { headers });
        }
      }
      break;
    }
    case INTENT_2FA: {
      let submission = await parseWithZod(formData, {
        async: true,
        schema: VerifySchema,
      });

      if (submission.status !== "success") {
        return {
          type: responseType.Error,
          error: "",
          submissionResult: submission.reply({ hideFields: ["password"] }),
        };
      } else {
        let auth = await checkAuthenticationStatus(
          request,
          AUTH_STRATEGY_LOGIN
        );

        let isCodeOk = await isCodeValid({
          code: submission?.value?.code ?? "",
          loginID: auth.loginID,
          verificationType: verificationTypeAlready,
        });

        if (!isCodeOk) {
          return {
            type: responseType.CodeError,
            error: "error:twoFACodeNotValid",
          };
        }

        // manually get the session
        let session = await getSession(request.headers.get(COOKIE));
        session.set(AUTH_COOKIEKEY, auth);

        // commit the session
        let headers = new Headers({
          "Set-Cookie": await commitSession(session),
        });

        throw redirect("/", { headers });
      }
    }
  }

  let submission = await parseWithZod(formData, {
    async: true,
    schema: LoginFormSchema.superRefine(async (data, ctx) => {
      if (authError) {
        ctx.addIssue({
          path: ["AuthError"],
          code: z.ZodIssueCode.custom,
          message: authError,
        });
      }
    }),
  });

  if (submission.status !== "success") {
    return {
      type: responseType.Error,
      error: "",
      submissionResult: submission.reply({ hideFields: ["password"] }),
    };
  }

  return {
    type: responseType.TwoFA,
  };
};

export default function Login() {
  let loaderData = useLoaderData<typeof loader>();
  let actionData = useActionData<typeof action>();
  // let loaderData = useLoaderData<typeof loader>();
  let [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(Login2FAFormSchema),
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: Login2FAFormSchema });
    },
    shouldRevalidate: "onBlur",
  });

  let { t } = useTranslation();

  return (
    <>
      <div className="flex mx-auto min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-bg-light dark:bg-bg-dark">
        <ThemeSwitch userPreference={loaderData.theme} />
        <div className="sm:mx-auto sm:w-full sm:max-w-sm lg:w-41">
          <Logo theme={loaderData.theme} />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-text-dark">
            {t("message:signIn")}
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm lg:w-41">
          <Form method="post" {...getFormProps(form)} className="space-y-6">
            <HoneypotInputs />

            <div>
              <div>
                <Field
                  labelProps={{ children: "E-Mail" }}
                  inputProps={{
                    ...getInputProps(fields.email, { type: "text" }),
                    autoFocus: true,
                    className: "lowercase",
                    autoComplete: "email",
                  }}
                  errors={fields.email.errors}
                />
              </div>
              <div>
                <Field
                  labelProps={{ children: "Password" }}
                  inputProps={{
                    ...getInputProps(fields.password, { type: "password" }),
                  }}
                  errors={(fields.password.errors ?? []).concat(
                    actionData?.type === responseType.Error &&
                      actionData?.submissionResult?.error?.AuthError
                      ? actionData.submissionResult.error.AuthError
                      : []
                  )}
                />
              </div>
              {(actionData?.type === responseType.TwoFA ||
                actionData?.type === responseType.CodeError) && (
                <Field
                  labelProps={{ children: "Code" }}
                  inputProps={{
                    ...getInputProps(fields.code, { type: "text" }),
                    autoFocus: true,
                    className: "lowercase",
                  }}
                  errors={fields.code.errors}
                />
              )}{" "}
              {actionData?.type === responseType.CodeError && (
                <ErrorBox errorList={[actionData.error]}></ErrorBox>
              )}
            </div>
            <div className="flex justify-center items-center mx-auto">
              {actionData?.type === responseType.TwoFA ||
              actionData?.type === responseType.CodeError ? (
                <Button name={SUBMIT_INTENT} value={INTENT_2FA} type={"submit"}>
                  {t("login")}
                </Button>
              ) : (
                <Button
                  name={SUBMIT_INTENT}
                  value={INTENT_LOGIN}
                  type={"submit"}
                >
                  {t("login")}
                </Button>
              )}
            </div>
          </Form>
          <Form method="POST">
            <div className="flex justify-center items-center mx-auto mt-4 space-x-4">
              <Button
                name={SUBMIT_INTENT}
                value={INTENT_FORGOTPASSWORD}
                className="text-sm font-medium text-button-light dark:text-button-dark hover:text-link-light dark:hover:text-link-dark"
                type={"submit"}
              >
                {t("forgotPassword")}
              </Button>
              <Link
                to={ROUTE_REGISTERCOMPANY}
                className="text-sm font-medium text-button-light dark:text-button-dark hover:text-link-light dark:hover:text-link-dark"
              >
                {t("register")}
              </Link>
            </div>
          </Form>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm lg:w-41 flex w-full justify-center mt-6">
          <Outlet />
        </div>
      </div>
    </>
  );
}
